import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

export default ({ location }) => (
  <Layout className="policiesPage policiesPage--cookies">
    <SEO
      title="LicenseSpring: Cookie Policy"
      path={location.pathname}
      description={
        "Learn about our cookie policy and how LicenseSpring uses cookies to enhance your browsing experience and improve website functionality."
      }
    />
    <div className="container">
      <h1>LICENSESPRING COOKIE POLICY</h1>
      <p>
        {" "}
        This policy contains full information about the cookies that we use on
        www.licensespring.com (hereinafter referred to as the “Site”) and the
        tools and knowledge to manage them.{" "}
      </p>
      <p>
        {" "}
        We are continuously making further improvements and changes to the ways
        we use cookies and we will keep this page updated with the cookies we
        use.{" "}
      </p>
      <p>
        {" "}
        Please refer also to our <Link to="/privacy">Privacy Policy.</Link>
      </p>
      <h2>WHAT ARE COOKIES?</h2>
      <p>
        Cookies are small text files that the websites visited by users send
        through to their terminals (computers, tablets, smartphones, notebooks,
        etc.), usually to the browser, where they are stored to then be
        retransmitted to the same websites when the same users subsequently
        revisit the sites.
      </p>
      <p>Please note that cookies will not damage your device.</p>
      <p>Below you can find a list of the categories of cookies:</p>
      <h3>TECHNICAL COOKIES</h3>
      <h4>Strictly necessary cookies</h4>
      <p>
        These cookies are essential for you to move around the Site, to use its
        features and provide the services you have requested, such as access to
        restricted areas of the website.
      </p>
      <p>
        They can, for example, memorize previous actions (e.g., entered text,
        etc.) when navigating back to a page in the same session, manage
        security tokens for different services within the Site to identify the
        status of the visitor (e.g., registered or unregistered), etc.
      </p>
      <h4>Performance / Analytics cookies</h4>
      <p>
        These cookies can be session or persistent, and their use is limited to
        the performance and improvement of the Site features, such as collecting
        information on how a visitor uses the Site.
      </p>
      <p>
        They can do, for example, web analytics, error handling, test different
        website page structures, etc.
      </p>
      <p>
        {" "}
        <u>
          These cookies do not collect information that can identify you, as all
          information collected by them are aggregated in an anonymous form and
          are only used to improve the functionality of the Site
        </u>
        .
      </p>
      <h4>Functionality cookies</h4>
      <p>
        These cookies can be session or persistent, and are usually the result
        of a user action, but they can also be implemented when providing a
        service not specifically requested but simply offered to users.
      </p>
      <p>
        They can, for example, remember the settings that a user has applied to
        a website (such as layout, font size, preferences, colors, etc.),
        remember a choice so that users are no longer requested to fill out a
        questionnaire, detect if a service has already been offered, provide
        information to allow access to an optional service (such as the offer of
        a live chat session), fulfil a user request (such as submitting a
        comment).
      </p>
      <h3>PROFILING COOKIES</h3>
      <h4>
        Third-party profiling cookies for marketing / retargeting purposes
      </h4>
      <p>
        These cookies are used by our partners, upon your prior consent, in
        order to profile users for advertising and retargeting purposes.
      </p>
      <p>
        They can, for example, create user profiles to serve ads that are in
        line with your preferences showed as you surf the web; record your
        choices and activity on our website to carry out statistical analysis –
        for us or for third parties – by tracking your preferences and browsing
        behavior, serve customized ads to you, integrate some common feature of
        the major social media and provide it within the site, etc.
      </p>
      <p>
        {" "}
        <strong>We do not use any profiling cookies in our Site.</strong>{" "}
      </p>
      <h3>COOKIES USED ON LICENSESPRING.COM</h3>
      <h3>We use the following cookies on our Site:</h3>
      <table>
        <thead>
          <tr>
            <th>Name of cookie</th>
            <th>Type / Purpose</th>
            <th>Duration</th>
            <th>Source and ownership</th>
            <th>Access to more information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td datatype="Name of cookie">
              _ga
              <br />
              _gid
            </td>
            <td datatype="Type / Purpose">
              Performance / Analytics cookies. These cookies allow us to measure
              information about how visitors use our site. This includes
              identifying each unique user to our site and details of each page
              visited.
            </td>
            <td datatype="Duration">
              2 years
              <br />1 day
            </td>
            <td datatype="Source and ownership">
              Google Inc. (Google Analytics)
            </td>
            <td datatype="Access to more information">
              You can block the use of Google Analytics cookies by downloading
              and installing the relevant extra component for your browser from
              this link:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tools.google.com/dlpage/gaoptout"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
            </td>
          </tr>
          <tr>
            <td datatype="Name of cookie">
              {" "}
              __cfduid <br /> __hssc <br /> __hssrc <br /> __hstc <br />{" "}
              _biz_flagsA <br /> _biz_nA <br /> _biz_pendingA <br /> _biz_sid{" "}
              <br /> _biz_uid <br /> _uetsid <br /> cp_zopim_visited <br />{" "}
              hubspotutk <br /> km_ai <br /> km_lv <br /> km_vs <br /> kvcd{" "}
              <br /> optimizelyEndUserId <br />
            </td>
            <td datatype="Type / Purpose">
              These cookies allow us to provide you customer support.
            </td>
            <td datatype="Duration">Various</td>
            <td datatype="Source and ownership">Zendesk Inc.</td>
            <td datatype="Access to more information">
              {" "}
              For more information on Zendesk cookies, please{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.zendesk.com/company/customers-partners/cookie-policy/"
              >
                click here
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td datatype="Name of cookie">
              {" "}
              _ga <br /> _gid <br /> _gat <br /> AMP_TOKEN{" "}
            </td>
            <td datatype="Type / Purpose">
              {" "}
              These cookies are used by our partners in order to profile users
              for advertising and retargeting purposes.
            </td>
            <td datatype="Duration">Various</td>
            <td datatype="Source and ownership">Google Analytics</td>
            <td datatype="Access to more information">
              {" "}
              These cookies are used by our partners in order to profile users
              for advertising and retargeting purposes. They can, for example,
              create user profiles to serve ads that are in line with your
              preferences showed as you surf the web; record your choices and
              activity on our website to carry out statistical analysis – for us
              or for third parties – by tracking your preferences and browsing
              behavior, serve customized ads to you, integrate some common
              feature of the major social media and provide it within the site,
              etc. For more information on these cookies, please click here{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en"
              >
                https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en
              </a>{" "}
              or if you want to opt-out click here{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tools.google.com/dlpage/gaoptout"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
            </td>
          </tr>
        </tbody>
      </table>

      <h3>HOW CAN I DISABLE COOKIES?</h3>
      <p>
        We remind you that, in compliance with current legislation on cookies,
        your prior consent is not necessary for technical cookies.
      </p>
      <p>
        Anyway, if you do not agree with the use of all or any cookie, you must
        configure the browser settings and disable the cookies or stop using the
        Site. By disabling cookies, however, the Site or some of its features
        may not work properly.
      </p>
      <p>
        To modify the use of cookies and block/delete them on your device,
        simply enter the browser settings.
      </p>
      <p>
        Configurations of the cookies may be different in each browser, even
        though the steps are very similar.
      </p>
      <p>
        For more details on the procedure, visit{" "}
        <a href="http://www.aboutcookies.org/">
          {" "}
          <u>http://www.aboutcookies.org</u>{" "}
        </a>{" "}
        or see the “Help” section of your specific browser.
      </p>
      <p>
        To learn more about cookies and to manage your preferences on
        third-party cookies please visit{" "}
        <a href="http://www.youronlinechoices.com/">
          {" "}
          <u>http://www.youronlinechoices.com</u>{" "}
        </a>
        .
      </p>
    </div>
  </Layout>
)
